<template>
    <div>
        <div class="flex_center" style="height:100vh">
            <div class="form" :class="{ mobile: isMobile }">
                <el-image :style="{ width: '153px' }" :src="require('@/assets/imgs/layout/logo.png')" class="logo" alt="职路数字"></el-image>
                <p class="fs_20 bold one color_333" :style="{ 'margin-bottom': '10px' }">
                    欢迎加入「{{ company_name }}」
                </p>
                <p class="fs_14 one color_999" :style="{ 'margin-bottom': '40px' }">
                    {{ invite_name }} 邀请您加入企业，请完善您的账户信息！
                </p>

                <template v-if="step == 1">
                    <el-form :model="ruleForm" :rules="nextRules" ref="nextForm">
                        <el-form-item label="" prop="phone">
                            <el-input placeholder="手机号码" class="flex_c" maxlength="11" type="number" v-model.trim="ruleForm.phone">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="captcha">
                            <el-input placeholder="验证码" maxlength="6" type="number" v-model.trim="ruleForm.captcha">
                                <template #suffix>
                                    <el-button type="text" class="btn" v-if="!sandSuccess" :loading="sandLoading" @click="sendCode">
                                        获取验证码
                                    </el-button>
                                    <el-button type="text" class="btn" v-else>
                                        {{ sandTime }}s后重新获取
                                    </el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" class="fs_16 loginer" :loading="loading" @click="next">
                                下一步
                            </el-button>
                        </el-form-item>
                    </el-form>
                </template>
                <template v-else>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                        <p class="fs_14 one color_999" :style="{ 'margin-bottom': '10px' }">
                            欢迎手机号{{ ruleForm.phone }}加入公司，请完善您的信息
                        </p>
                        <el-form-item label="" prop="realname">
                            <el-input placeholder="真实姓名" class="flex_c" type="text" v-model.trim="ruleForm.realname">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="idcard">
                            <el-input placeholder="身份证号" class="flex_c" maxlength="18" type="text" v-model.trim="ruleForm.idcard">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="dept_name">
                            <el-input placeholder="部门" class="flex_c" type="text" v-model.trim="ruleForm.dept_name">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="job_name">
                            <el-input placeholder="职位" class="flex_c" type="text" v-model.trim="ruleForm.job_name">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" class="fs_16 loginer" :loading="loading" @click="submit">
                                加入企业
                            </el-button>
                        </el-form-item>
                    </el-form>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { setToken } from "@/common/cache"
import { isPhone, isIdCard } from "@/common/validate"
var checkCode = (rule, value, callback) => {
	if (value === "") {
		callback(new Error("请输入验证码"))
	} else if (value.length < 6) {
		callback(new Error("请输入6位数验证码"))
	} else {
		callback()
	}
}
export default {
	name: "share",
	data() {
		var checkMobile = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入手机号码"))
			} else if (!isPhone(value)) {
				callback(new Error("请输入正确的手机号码"))
			} else {
				callback()
			}
		}
		var checkIdcard = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入身份证号"))
			} else if (!isIdCard(value)) {
				callback(new Error("请输入正确的身份证号"))
			} else {
				callback()
			}
		}
		return {
			key: '',
			invite_name: '',
			company_name: '',
			ruleForm: {
				phone: "",
				captcha: "",
				realname: "",
				idcard: "",
				dept_name: "",
				job_name: ""
			},
			step: 1,
			checked: true,
			sandSuccess: false,
			loading: false,
			sandLoading: false,
			sandTime: 60,
			nextRules: {
				phone: [ { validator: checkMobile, trigger: "blur" } ],
				captcha: []
			},
			rules: {
				realname: [
					{ required: true, message: "请输入姓名", trigger: "change" }
				],
				idcard: [ { validator: checkIdcard, trigger: "blur" } ],
				dept_name: [
					{ required: true, message: "请输入部门", trigger: "change" }
				],
				job_name: [ { required: true, message: "请输入职位", trigger: "change" } ]
			},
			isMobile: false
		}
	},
	created() {
		console.log(this.$route)
		this.key = this.$route.query.key
		var ua = navigator.userAgent

		var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
			isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
			isAndroid = ua.match(/(Android)\s+([\d.]+)/)
		this.isMobile = isIphone || isAndroid
		this.init()
	},
	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/invite/${this.key}`,
				method: "GET",
				hideLoading: true,
				no_carryToken: true
			}).then(res => {
				if (res.code === 200) {
					this.invite_name = res.data.invite_name
					this.company_name = res.data.company_name
				}
			})
		},
		sendCode() {
			this.nextRules.captcha = []
			this.$refs.nextForm.validate(valid => {
				if (valid) {
					this.sandLoading = true
					this.$axios({
						url: "/api/v2/account/verify?type=login",
						method: "POST",
						data: {
							phone: this.ruleForm.phone,
							type: "login"
						},
						no_carryToken: true,
						hideLoading: true,
						shake: true
					}).then(res => {
						if (res.code === 200) {
							this.$message.success(res.msg)
							this.sandSuccess = true
							this.countTime()
						} else {
							setTimeout(() => {
								this.sandLoading = false
							}, 900)
						}
					})
				}
			})
		},
		next() {
			this.nextRules.captcha.push({ validator: checkCode, trigger: "blur" })
			this.$refs.nextForm.validate(valid => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: `/ent/v3/invite/login/${this.key}`,
						method: "POST",
						data: this.ruleForm,
						no_carryToken: true,
						hideLoading: true,
						shake: true
					}).then(res => {
						this.loading = false
						if (res.code === 200) {
							setToken(res.data.token)
							if (res.data.user) {
								this.ruleForm.idcard = res.data.user.idcard
								this.ruleForm.realname = res.data.user.realname
								this.ruleForm.idcard = res.data.user.idcard
							}
							this.step = 2
						}
					})
				} else {
					console.log("error submit!!")
					return false
				}
			})
		},
		submit() {
			this.$refs.ruleForm.validate(valid => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: `/ent/v3/invite/join/${this.key}`,
						method: "POST",
						data: this.ruleForm,
						hideLoading: true,
						shake: true
					}).then(res => {
						if (res.code === 200) {
							setTimeout(() => {
								this.$router.replace("/")
							}, 1500)
						} else {
							this.loading = false
						}
					})

				}
			})
		},
		countTime() {
			var currentTime = 60
			var interval = setInterval(() => {
				this.sandTime = currentTime--
				if (currentTime < 0) {
					clearInterval(interval) // 清除计时器
					this.rules.captcha = []
					this.sandSuccess = false
					this.sandTime = 60
				}
			}, 1000)
		}
	}
}
</script>
<style lang="less">
@media only screen and (max-width: 500px) {
    html,
    body {
        width: 100%;
        min-width: 100% !important;
        padding: 0;
        margin: 0;
    }
}
</style>
<style lang="less" scoped>
.flex_center {
    display: flex;
    justify-content: center;
}
.logo {
    margin: 0 auto;
    margin-bottom: 64px;
    display: block;
}
@media only screen and (max-width: 500px) {
    .form {
        &.mobile {
            box-sizing: border-box;
            width: 100%;
            padding: 0 30px;
            padding-top: 48px;
            // transform: scale(.5);
        }
    }
}
/deep/.form {
    padding: 160px 0;
    // margin-top: 160px;
    width: 400px;
    height: 675px;
    border-radius: 20px;
    // overflow: hidden;
    .el-form {
        .el-input__inner {
            height: 44px;
            margin-bottom: 5px;
            background: #f9f9f9;
            // border-color: #e3e3e3;
            padding-left: 20px;
            font-size: 14px;
            border-radius: 0px;
        }
        .btn {
            color: #333;
            line-height: 26px;
            margin-right: 10px;
        }
        .loginer {
            width: 100%;
            height: 52px;
            background: #1890ff;
            border: none;
            margin-top: 60px;
            border-radius: 0px;
        }
    }
}
</style>
